<template>
  <v-container class="landing-page" fluid>
    <v-row class="align-center content">
      <v-col cols="12" md="7" class="text-section">
        <div class="pl-md-16">
          <img src="@/assets/logo.png" alt="Logo" class="logo">
        </div>
        <div class="pl-md-16">
          <h1>CRIE SUA LOJA EM APENAS <span class="highlight">1 MINUTO!</span></h1>
          <p>Tenha sua <span class="highlight">loja personalizada</span> e lucre com diversos produtos prontos para
            venda em apenas <span class="highlight">1 minuto</span>.</p>
          <div class="pt-md-16">
            <v-btn class="cta-button" color="yellow"><strong>Quero minha loja!</strong></v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="5" class="image-section">
        <img src="@/assets/Homem.png" alt="Homem" class="main-image">
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
export default {
  name: "HeaderPage"
};
</script>

<style scoped>
.landing-page {
  overflow: hidden;
  background-image: url('@/assets/background/Landing-page-Franqueados-Beeasy_01.png');
  background-size: cover;
  background-position: center;
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-inline: 20px;
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.text-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  padding-left: 16px;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.highlight {
  color: yellow;
}

p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.cta-button {
  font-size: 1rem;
}

.image-section {
  padding: 0;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: flex-end;
  height: 100%;
  width: 100%;
}

.main-image {
  object-fit: cover;
  width: 95%;
  bottom: 0;
  height: auto;
  margin-bottom: -5%;
}

@media (max-width: 600px) {
  .text-section {
    align-items: center;
    text-align: center;
    padding-left: 0;
  }

  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 1rem;
    width: 100%;
  }
}

@media (max-width: 959px) {

  * {}

  .image-section {
    display: none;
  }
}
</style>
