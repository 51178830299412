<template>
  <v-container fluid class="footer-section">
    <v-row class="footer-content">
      <v-col cols="12" class="text-center">
        <h3 class="footer-title">Alguma dúvida?</h3>
        <p class="footer-description">Fale com nossa equipe.</p>
        <v-btn class="cta-button" color="yellow" href="https://api.whatsapp.com/send?l=pt&phone=558597738263"
          target="_blank">
          Entre em contato
        </v-btn>
        <div class="footer-logo">
          <img src="@/assets/logo.png" alt="Beeasy Logo">
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'FooterSection',
};
</script>

<style scoped>
.footer-section {
  background-image: url('@/assets/background/Landing-page-Franqueados-Beeasy_06.png');
  background-size: cover;
  background-position: center;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-content {
  text-align: center;
  color: white;
}

.footer-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-description {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.cta-button {
  background-color: #FFC107;
  color: black;
  font-weight: bold;
  padding: 12px 30px;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.footer-logo img {
  max-width: 150px;
  height: auto;
}
</style>
