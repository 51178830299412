<template>
  <v-container fluid class="join-section">
    <v-row class="fill-height join-content">
      <v-col cols="12" class="text-center join-col">
        <div class="logo-container">
          <img src="@/assets/Logo Beeasy blur.png" class="logo-beeasy" />
          <img src="@/assets/Abelha.png" class="bee-image" />
        </div>
        <div class="join-text">
          <h2>FAÇA PARTE DESSA <strong style="color: #D5FF40;">COLMEIA!</strong></h2>
          <p>
            Faça parte da família <span style="color: #D5FF40;">Beeasy</span> e se torne um franqueado de sucesso!
          </p>
          <v-btn class="cta-button" color="yellow" href="https://api.whatsapp.com/send?l=pt&phone=558597738263"
            target="_blank">
            Quero minha loja!
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: 'JoinSection'
}
</script>

<style scoped>
.join-section {
  background-image: url('@/assets/background/Landing-page-Franqueados-Beeasy_05.png');
  background-size: cover;
  background-position: center;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.join-content {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.join-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-container {
  position: relative;
}

.logo-beeasy {
  max-width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bee-image {
  max-width: 600px;
  position: relative;
}

.join-text {
  color: white;
  text-align: center;
  margin-top: 20px;
}

.join-text h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.join-text p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #FFC107;
  color: black;
  font-weight: bold;
  padding: 12px 30px;
  font-size: 1.2rem;
}

/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
  .join-section {
    height: auto;
    padding: 20px;
  }

  .join-text h2 {
    font-size: 2rem;
  }

  .join-text p {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .logo-beeasy {
    width: 80%;
    max-width: 200px;
  }

  .bee-image {
    width: 80%;
    max-width: 600px;
  }
}
</style>
