<template>
  <v-app>
    <v-main>
      <HeaderPage />
      <HowItWorks />
      <PaymentSupportTransparency />
      <FinancialControlSection />
      <JoinSection />
      <FooterSection />
    </v-main>
  </v-app>
</template>

<script>
import HeaderPage from '@/components/HeaderPage.vue';
import HowItWorks from '@/components/HowItWorks.vue';
import PaymentSupportTransparency from '@/components/PaymentSupportTransparency.vue';
import FinancialControlSection from '@/components/FinancialControlSection.vue';
import JoinSection from '@/components/JoinSection.vue';
import FooterSection from './components/FooterSection.vue';

export default {
  name: 'App',
  components: {
    HeaderPage,
    HowItWorks,
    PaymentSupportTransparency,
    FinancialControlSection,
    JoinSection,
    FooterSection
  }
};
</script>

<style>
@import '~vuetify/dist/vuetify.min.css';
</style>
